/* fonts impot */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --gray_bg: #F9F9F9;
    --black: #000;
    --white: #ffffff;
    --secondary_black: #ADADAD;
    --input_border: #282828;
    --placeholder: #959595;
    --primary: #0081F9;
    --hover_primary: #0081F930;
    --navigation: #0081F9;
    --light_grey: #6B6B6B;
    --lower_font: 8px;
    --small_font: 10px;
    --font_twelve: 12px;
    --font_thirteen: 13px;
    --font_fourteen: 14px;
    --font_fifteen: 15px;
    --font_sixtee: 16px;
    --font_eighteen: 18px;
    --font_twenty: 20px;
    --font_twentyfour: 24px;
    --font_thirty: 30px;
    --font_thirty_two: 32px;
    --highter_font: 25px;
    --weight_four: 400;
    --weight_five: 500;
    --weight_six: 600;
    --weight_seven: 700;
    --weight_eight: 800;
    --weight_nine: 900;
}



.box-shadow {
    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.25);
}


.fs-8 {
    font-size: var(--lower_font);
}

.fs-10 {
    font-size: var(--small_font);
}

.fs-12 {
    font-size: var(--font_twelve);
}

.fs-13 {
    font-size: var(--font_thirteen);
}

.fs-14 {
    font-size: var(--font_fourteen);
}

.fs-15 {
    font-size: var(--font_fifteen);
}

.fs-16 {
    font-size: var(--font_sixtee);
}

.fs-18 {
    font-size: var(--font_eighteen);
}

.fs-20 {
    font-size: var(--font_twenty);
}

.fs-24 {
    font-size: var(--font_twentyfour);
}

.fs-25 {
    font-size: var(--highter_font);
}

.fs-30 {
    font-size: var(--font_thirty);
}

.fs-32 {
    font-size: var(--font_thirty_two);
}




.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

/* common css */
* {
    font-family: "Montserrat", sans-serif;
}

.montserrat {
    font-family: "Montserrat", sans-serif;
}

.cmn__bgAligner {
    overflow-x: hidden;
}

.pointer {
    cursor: pointer !important;
}

/* end of common css */

/* sigin css */
.signIn__commonBg {
    width: 100%;
    height: 100vh;
    background-image: url('../Images/background.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

}

.signIn__logo {
    width: 400px;
    height: 100px;
}

.singIn__formTitle {
    color: var(--secondary_black);
    font-size: var(--font_twenty);
    font-weight: var(--weight_seven);

}

.signIn__formHolder {
    width: fit-content;
    min-width: 50%;
    background-color: var(--gray_bg);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.222);
}

.cmn__inputHolder {
    border: 1px solid var(--input_border);
}

.signIn__inputter {
    border: none;
    outline: none;
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    background-color: transparent;
    min-width: 250px;
}

.signIn__inputter::placeholder {
    color: var(--placeholder);
}

.singIn__forgotPw {
    color: var(--navigation);
    font-size: var(--font_twelve);
    font-weight: var(--weight_six);
}

.primary__btn {
    border: 1px solid var(--navigation);
    outline: none;
    background-color: var(--navigation);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    padding: 5px 40px;
    width: fit-content;
}

.signIn__eye {
    position: absolute;
    top: 30%;
    right: 5%;
}

/* end of signin css */

/* otp verification css */
.otpVerify__hint {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}

.otpVerify__hint span {
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
}

.cmn__otpInpHolder input {
    height: 66px;
    width: 59px !important;
    border: 1px solid var(--input_border);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    color: var(--black);
    outline: none;
    text-align: center;
    margin-right: 26px;

}

.otpVerify__plainbutton {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}

.bg-yellow {
    background-color: #FFD749;
    /* position: fixed;
    z-index: 1000; */
}

.cmn__otpInpHolder span {
    display: none;
}

.otpVerify__countdown {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}

/* end of otp verification css */

/* preference start */
.p-multiselect-items {
    padding: 0;
    margin: 0;
}

.preference__formHolder {
    font-family: "Montserrat", sans-serif;
    width: 587px;
    background-color: var(--gray_bg);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.222);
}

.forget__formHolder {
    font-family: "Montserrat", sans-serif;
    width: 487px;
    background-color: var(--gray_bg);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.222);
}

.p-multiselect-item {
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: var(--primary);
    color: white;
    border-bottom: .5px solid rgba(245, 245, 245, 0.324);
    padding: 10px;
}

.p-checkbox-input {
    background-color: transparent !important;
}

.multisel::placeholder {
    text-align: left;
}

.p-checkbox-box {
    border-radius: 0% !important;
    background-color: transparent !important;
    -webkit-border-radius: 0% !important;
    -moz-border-radius: 0% !important;
    -ms-border-radius: 0% !important;
    -o-border-radius: 0% !important;
    border: 1px solid white;
}

.custom-multiselect .p-checkbox-box input:checked+.p-checkbox-icon {
    border-color: white !important;

    /* Change to the border color for checked checkboxes */
}


.p-checkbox-input {
    border: none !important;
    border-radius: 0% !important;
    -webkit-border-radius: 0% !important;
    -moz-border-radius: 0% !important;
    -ms-border-radius: 0% !important;
    -o-border-radius: 0% !important;
    background-color: transparent !important;
}

.p-multiselect-header {
    display: none;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    text-align: left;
    font-size: var(--font_sixtee);
    color: var(secondary_black);
}

.p-multiselect-label {
    padding: 14px;
}

.custom-pad {
    margin: 5px;
    padding: 30px 40px 30px 40px;
}

/* preference end */

/* Suggest freinds */

.PhoneInputInput {
    border: none;
    height: 100%;
    width: 100%;
}

.PhoneInputInput:focus {
    border: none;
    outline: none;
}

.PhoneInputInput:focus {
    border: none;
}



.error-fields {
    padding: 6px;
    color: red;
    text-transform: capitalize;
    text-align: left;
}





.details__formHolder {
    font-family: "Montserrat", sans-serif;
    width: 54rem;
    height: 44rem;
    overflow-y: scroll;
    background-color: var(--gray_bg);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.222);
    margin: 5px;
}

.studentDetails__textarea {
    height: 200px;
}

.line-custom {
    width: 2px;
    height: 50px;
    background-color: black;
}

.btn-custom {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: white;
    border: 2px solid black;
    color: black;
}




/* WebKit browsers (Chrome, Safari) */
.details__formHolder::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.details__formHolder::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* color of the scrollbar track */
}

.details__formHolder::-webkit-scrollbar-thumb {
    background-color: #f1f0f0;
    /* color of the scrollbar thumb */
    border-radius: 10px;
    /* roundness of the scrollbar thumb */
    border: .1px solid #f1f1f1;
    /* creates padding around thumb */
}

.details__formHolder::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
    /* color of the scrollbar thumb when hovered */
}

/* Firefox */
.details__formHolder {
    scrollbar-width: thin;
    /* scrollbar width */
    scrollbar-color: #f1f1f1 #f1f1f1;
    /* scrollbar thumb and track color */
}

/* Edge, IE */
.details__formHolder {
    -ms-overflow-style: none;
    /* Hide default scrollbar in IE */
}

.details__formHolder::-ms-scrollbar {
    display: none;
    /* Hide default scrollbar in Edge */
}

/* Styles for Scrollspy Navigation */
.custom-left {
    left: auto;
}

.custom-top {
    top: 100%;
}



.active-scroll-spy {
    background-color: rgb(0, 0, 0) !important;
    color: white;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
}

/* .sideNavBarProfile{
    background-color: yellow !important;
    height: 50vh;
} */

.home__page-bg {
    background-color: #F0F6FF;
    /* height: 100vh; */
    height: auto;
}

.home__page-bg .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    color: var(--secondary-dark);
    font-weight: 600;
    background-color: transparent;
    border-color: 0;
    border: none;
}

.home__page-bg .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--primary);
    background-color: transparent;
    border-color: 0;
    border: none;
}

.home__page-bg .Works-upload .upload-border {
    max-width: 40rem;
    aspect-ratio: 16/12;
    border: 2px #747474 dashed;
    display: grid;
    place-items: center;
}

/* .home__page-bg .student-profile-board{
    background-color: red !important;
    height: 50vh;
} */

.upload-button {
    width: 92px;
    aspect-ratio: 17/17;
}


.Modal-uploaded-Works-upload {
    max-width: 40rem;
    aspect-ratio: 16/12;
    border: 2px #747474 dashed;
    display: grid;
    place-items: center;
}

/* !student profile */

.profile__tab {
    height: 80vh;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
}



.modal-70w {

    max-width: 70vw !important;
}

.modal-50w {

    max-width: 50vw !important;
}

.modal-40w {

    max-width: 40vw !important;
}

.p-multiselect-panel {
    z-index: 100000 !important;

}

.p-multiselect-token {
    background-color: var(--primary);
    color: white;
    border-radius: 0%;
    padding: 5px;
    margin-right: 7px;
    -webkit-border-radius: 0%;
    -moz-border-radius: 0%;
    -ms-border-radius: 0%;
    -o-border-radius: 0%;
}

.p-chips-token-icon {
    padding: 3px;
}

.p-multiselect-token-label {
    padding-right: 5px;
}

.student__profile_chips .p-chips-multiple-container {
    border: 1px solid var(--black);
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    padding: 15px;
    display: block;
    width: 100%;
}

.student__profile_chips .p-chips-token-icon {
    padding: 0px;
    width: 22px;
}

.p-chips-input-token {
    width: 100%;
}

.text-primary {
    color: var(--primary) !important;
}

.text-prime {
    color: var(--primary) !important;
}


.student__profile_chips .p-chips-token {
    background-color: var(--primary);
    color: white;
    border-radius: 0%;
    padding: 8px;
    margin-right: 7px;
    -webkit-border-radius: 0%;
    -moz-border-radius: 0%;
    -ms-border-radius: 0%;
    -o-border-radius: 0%;
    margin-bottom: 6px;
}

.student__profile_chips svg.p-chips-token-label .p-icon.p-chips-token-icon {
    width: 32px;
    height: 32px;
    display: none;
}


.bg-primary {

    background-color: var(--primary) !important;
}

.bg-prime {
    background-color: var(--primary) !important;
    color: white;
}

.student__profile__location {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.light-primary {
    background-color: var(--light-primary);
}

.css-43q4v5-multiValue {
    background-color: transparent !important;
}

.css-12a83d4-MultiValueRemove {
    display: none !important;
}

.css-43q4v5-multiValue::after {
    content: "," !important;
    /* background-color: transparent !important; */
}

/* ! NEW CSS */
.profile__tab {
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
}

.badge-fresher {
    background-color: var(--badge-fresher);
}

.cursor-disable {
    cursor: not-allowed;
}

.profile-page .card {
    max-width: 30rem !important;
}



@media screen and (max-width:989px) {
    .student__profile__location {
        display: grid;

        grid-template-columns: 1fr;
    }

    .modal-50w {

        max-width: 70vw !important;
    }

    .profile__tab {
        min-height: 80vh;
        padding: 7px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
    }

    .home__page-bg {
        background-color: #F0F6FF;
        height: auto;
        padding: 8px;
    }

    .modal-40w {

        max-width: 100vw !important;
    }

    .modal-70w {

        max-width: 100vw !important;
    }

    .custom-pad {
        padding: 10px;
    }

    .form-floating {
        width: 90%;
        margin-left: auto;
    }
}

@media(max-width:600px) {
    .student__profile__location {
        display: grid;

        grid-template-columns: 1fr;
    }

    .modal-50w {

        max-width: 100% !important;
    }
}