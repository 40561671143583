

/* Side navbar start */

.side-navbar {
    height: 87vh;
    width: 100px;
    border-radius: 5px;
    /* position: sticky; */
    /* top: 93px; */
    margin: 0px 10px !important;
}

@media only screen and (min-width:1068px) and (max-width:1188px)
{
    .side-navbar {
        margin: 0px 5px !important;
    }
}
@media only screen and (min-width:992px) and (max-width:1067px)
{
    .side-navbar {
        margin: 0px 3px 0px 5px !important;
    }
}

@media only screen and (min-width:320px) and (max-width:991px) {
    .side-navbar {
        display: flex !important;
        flex-direction: row !important;
        position: fixed !important;
        align-items: center !important;
        justify-content: center !important;
        width: 90% !important;
        height: 10% !important;
        top: 88% !important;
        gap: 70px !important;
        left: 3%;
        z-index: 1000;
        margin: 20px;
        /* background-color: orange !important; */
    }

    /* .side-navbar button:nth-child(1), .side-navbar button:nth-child(3)
    {
        background-color: red;
    }
    .side-navbar button:nth-child(2)
    {
        background-color: rgb(17, 0, 199);
    } */

    .side-navbar button:nth-child(4) {
        background-color: black;
        display: none !important;
    }
}

@media only screen and (min-width:1044px) and (max-width:1488px) {
    .side-navbar {
        height: 87vh;
        /* width: 80px; */
        width: 75px;
        border-radius: 5px;
        position: sticky;
        top: 93px;
    }
}

@media only screen and (min-width:992px) and (max-width:1043px) {
    .side-navbar {
        height: 87vh;
        /* width: 80px; */
        width: 70px;
        border-radius: 5px;
        position: sticky;
        top: 93px;
    }
}

/* Side navbar end */