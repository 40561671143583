/* 20*May-24 {Jayakumaar} Css Start */

.top-index {
    z-index: 10000;
}

@media only screen and (min-width:320px) and (max-width:991px) {
    .sticky-ninety-three {
        top: 73px !important;
    }
}


/* .sticky-bar {
    position: sticky !important;
    top: 100px !important;
    background-color: rgb(180, 180, 67);
    z-index: 10 !important;
} */
.sticky-bar-one {
    /* position: sticky;
    top:0px;
    background-color: rgb(64, 64, 25); */
    /* z-index: 1000; */
    position: sticky !important;
    top: 150px !important;
    background-color: rgb(196, 196, 44);
    /* z-index: 101; */
}

.banner .banner-image-container {
    height: 318px;
    width: 100%;
    /* background-color: rgb(0, 0, 0,0.3); */
    position: relative;
}

.banner-overlay {
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.4);
    position: absolute;
    top: 0px;
    left: 0px;
}


.student-banner-image {
    height: 100%;
    background-color: lightslategray;
    object-fit: cover;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .banner .banner-image-container {
        height: 250px;
    }

    .student-banner-image {
        height: 250px;
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .banner .banner-image-container {
        height: 200px;
    }

    .student-banner-image {
        height: 200px;
    }
}


/* .student-banner-image {
    height: 318px;
    background-color: lightslategray;
    object-fit: cover;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .student-banner-image {
        height: 250px;
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .student-banner-image {
        height: 200px;
    }
} */

.banner .carousel-control-prev,
.banner .carousel-control-next {
    display: none;
}

.banner .carousel-indicators {
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner .carousel-caption {
    position: absolute;
    right: 1%;
    bottom: 3.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    /* color: #fff; */
    text-align: center;
    /* background-color: #595959; */
    width: 80%;
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .banner .carousel-caption {
        bottom: 0.50rem;
    }
}



.banner .carousel-indicators button {
    background-color: var(--white);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    opacity: 1;
    border: 0px solid transparent;

}

.banner .carousel-indicators .active {
    background-color: var(--primary);
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: none;
}


.stuProfile-image {
    height: 54px;
    width: 54px;
    object-fit: cover;
    object-position: center;
}



/* Filter start */
.filtered-column {
    height: auto;
}

.filter-section {
    height: 80px;
    background-color: var(--white);
}


.filters {
    width: 25%;
}


@media only screen and (min-width:320px) and (max-width:991px) {
    .filters {
        width: 50%;
    }
}

#filters.p-dropdown {
    background-color: var(--primary);
}

.p-dropdown-label,
.p-dropdown-trigger {
    color: white;
}

.p-dropdown-panel {
    background-color: white;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: transparent;
    outline: none;
    outline-offset: 0px;
}

.p-dropdown-item:hover {
    background-color: var(--hover_primary);
    color: black;
}

.p-highlight {
    background-color: var(--primary);
    color: white;
}

.p-dropdown-items {
    padding: 0px;
    margin: 0px;
}

/* Filter end */


/* Student details style start */

.student-details-container {
    height: auto;
}

/* @media only screen and (min-width:420px) and (max-width:1108px)
{
    .student-details-container{
        display: none !important;
    }
} */

.student-profile-main-container {
    height: auto;
    background-color: var(--white);
}

.student-desc-profile-container {
    height: auto;
    background-color: var(--white);
}

.student-profile-main-container .student-profile-container {
    height: auto;
    /* background-color: red; */
}


.student-profile-main-container .student-profile-container .student-profile-image-container {
    height: auto;
    width: 100%;
}

.student-profile-image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.student-profile-main-container .student-profile-container .student-profile-details {
    height: auto;
    width: 100%;
}

.user-details .location-details .location {
    color: #6B6B6B;
}

.user-details .course-name {
    color: #6B6B6B;
}


.student-profile-container .student-profile-details .btn-go-profile {
    background-color: var(--primary);
    color: var(--white);
    border-radius: 5px;
}


/* Student details style end */

/* Card items container start*/


.card-main-container {
    height: auto;
    /* background-color: var(--white); */
}

/* @media only screen and (min-width:420px) and (max-width:1247px)
{
    .card-main-container {
        height: auto;
        width: 805;
    }
} */

.card-main-container .card-container {
    height: auto;
    width: 100%;
}

.student-card-information {
    height: 100%;
    width: 100%;
}

.student-card-information .student-image {
    width: 10%;
    height: auto;
}

.student-card-information .student-image .stuPost-image {
    height: 74px;
    width: 74px;
    /* background-color: rebeccapurple; */
    /* border-radius: 50%; */
}

.student-card-information .student-content {
    height: 100%;
    width: 80%;
}


.student-card-information .student-work-share {
    width: 10%;
    height: auto;
}


.student-work-information {
    height: 85%;
    width: 100%;
}

.student-work-information .student-work-image {
    height: auto;
    width: 100%;
    /* background-color: yellow;
    object-fit: cover;
    object-position: center; */
}





/* .sticky-row-containers{
    position: sticky;
    top:0px;
    background-color: yellow;
} */
/* Card items container end*/








.news-panel {
    height: auto;
    /* background-color: var(--primary); */
}

.news-panel .news-section-container {
    background-color: var(--white);
}

.news-section-container .news-section {
    height: 350px;
    /* background-color: orange; */
}

.news-section-container .updated-news-heading-container {
    background-color: var(--primary);
    height: 10%;
}

.news-section-container .updated-news-heading {
    color: var(--white);
}

.news-section .updated-news {
    overflow-x: hidden;
    height: 90%;
    /* background-color: orange; */
}

.news-section .updated-news::-webkit-scrollbar {
    display: none;
}

/* For other browsers */
.news-section .updated-news {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}



@media only screen and (min-width:1369px) and (max-width:1600px) {
    .news-section-container .news-section {
        height: 300px;
    }

    .news-section-container .updated-news-heading-container {
        background-color: var(--primary);
        height: 15%;
    }

    .news-section .updated-news {
        overflow-x: hidden;
        height: 85%;
    }
}

@media only screen and (min-width:768px) and (max-width:1368px) {
    .news-section-container .news-section {
        /* height: 442px; */
        height: 350px;
        /* background-color: orange; */
    }
}






/* Add students container start */

/* .placed-students-main-container  */
.placed-students-section {
    /* height: 400px; */
    height: 350px;

    /* height: 380px; */
    width: 100%;
    /* position: relative; */
    /* background-color: orange; */
    /* z-index: 1; */
}

.placed-students-section .p-section-sub-container {
    height: 100%;
    width: 100%;
    /* background-color: rgb(248, 192, 192); */

}

.p-section-sub-container .placed-students-heading {
    height: 10%;
    width: 100%;
    /* background-color: lightcyan; */
}

.p-section-sub-container .mySwiper {
    height: 110% !important;
    width: 100%;
    /* background-color: lightgreen; */
    /* padding-bottom:550px ; */

}

.mySwiper .swiper-wrapper .swiper-slide {
    height: 85%;
    width: 100%;
    /* background-color: lightblue; */
    /* padding-bottom:550px ; */

}

.swiper-slide .swiper-image-container {
    height: 80%;
    width: 100%;
    /* background-color: lightcoral; */
    /* padding-bottom:550px ; */
}

.swiper-slide .swiper-image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    /* background-color: lightcoral; */
}

.swiper-slide .swiper-caption {
    height: 20%;
    width: 100%;
    /* background-color: lightcoral; */
    /* padding-bottom:550px ; */
}

@media only screen and (min-width:768px) and (max-width:1368px) {
    .placed-students-section {
        height: 350px;
        /* height: 380px; */
        width: 100%;
        /* position: relative; */
        /* background-color: orange; */
        /* z-index: 1; */
    }

    .swiper-slide .swiper-caption {
        height: auto;
        width: 100%;
        /* background-color: rgb(102, 172, 199); */
        /* padding-bottom:550px ; */
    }

}


@media only screen and (min-width:1369px) and (max-width:1600px) {
    .placed-students-section {
        /* height: 370px; */
        height: 360px;
    }
}



.swiper-pagination-bullet {
    background-color: var(--primary) !important;
    height: 13px !important;
    width: 13px !important;
    margin: 3px !important;
}


/* .swiper .swiper-pagination{


    height: 30px;
    width: 50% !important ;
    display: flex;
    align-items: center;
    justify-content: center;

}


.swiper-pagination-bullet {
    background-color: var(--primary) !important;
    height: 12px !important;
    width: 12px !important;
    margin: 3px !important;
} */




/* Custom Swiper Navigation Styles */
.swiper-button-next,
.swiper-button-prev {
    top: 35% !important;
    color: #000;
    /* Change the color of the icons */
    font-size: 20px !important;
    /* Change the size of the icons */
    width: 30px;
    /* Change the width of the buttons */
    height: 30px;
    /* Change the height of the buttons */
}

/* Using custom icons */
.swiper-button-next::after,
.swiper-button-prev::after {
    font-family: 'Font Awesome 5 Free';
    /* Assuming you're using Font Awesome */
    font-weight: 900;
    color: var(--white);
    /* opacity: 0.7; */
    font-size: 20px !important;
    content: "\f061";
    /* Unicode for custom next icon */
}



.updated-news ul li .updated-news-time {
    color: var(--secondary_black);
}

/* 20*May-24 Css End */

/* karthick css */
.p-dropdown-items .p-dropdown-item{
padding: 5px 15px !important;
}
/* end of karthick css */